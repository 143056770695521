import * as styled from 'styled-components';

export default styled.createGlobalStyle`
  :root {
    --white: #fdfffc;
    --dark: #212529;
    --gray: #878a8f;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; /* 10px = 1rem */
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-size: 1.6rem; /* 1rem = 10px */

    background-color: var(--white);
    color: var(--dark);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  img,
  svg {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    border-style: none;
  }

  .container {
    width: 95%;
    max-width: 130rem;
    margin: 0 auto;
  }

  /* Smooth Scroll  */
  [data-scrollbar] {
    height: 100vh;
    overflow: hidden;
    background-color: var(--white);
    .scroll-content {
      background-color: var(--white);
    }
    .scrollbar-track.scrollbar-track-y {
      z-index: 101;
      background: var(--gray);
      .scrollbar-thumb-y {
        background: var(--dark);
      }
    }
  }

  *::selection {
    background: var(--gray);
    color: var(--white);
  }

  /* Firefox */
  *::-moz-selection {
    background: var(--gray);
    color: var(--white);
  }
`;
