import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProjectImg from '../assets/images/projects-img/projectImg.png';

const ProjectItemStyles = styled.div`
  .project-item__img {
    height: 35rem;
    width: 100%;
    overflow: hidden;
    border-radius: 1.2rem;
    display: inline-block;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .project-item__info {
    margin-top: 2rem;
    background-color: var(--dark);
    color: var(--white);
    padding: 0 2rem;
    border-radius: 1.2rem;
  }

  .project-item__title {
    font-size: 2.2rem;
  }

  .project-item__description {
    margin-top: 1.5rem;
  }

  .project-item__button {
    display: inline-block;
    font-size: 1.8rem;
    text-decoration: underline;
    margin: 2rem 0;
  }

  @media only screen and (min-width: 768px) {
    .project-item__img {
      height: 40rem;
    }
  }
`;

function ProjectItem({ img, name, description, link }) {
  return (
    <ProjectItemStyles>
      <Link to="/projects" className="project-item__img">
        <img src={img} alt="" />
      </Link>
      <div className="project-item__info">
        <Link to="#">
          <h3 className="project-item__title">{name}</h3>
        </Link>
        <p className="project-item__description">{description}</p>
        {link && (
          <a className="project-item__button" href={link} target="_blank" rel="noreferrer">
            Open Project
          </a>
        )}
      </div>
    </ProjectItemStyles>
  );
}

ProjectItem.defaultProps = {
  img: ProjectImg,
  name: 'Project Name',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, ducimus dolorum. At officiis autem delectus laboriosam quam quasi deleniti consectetur.',
  link: '#',
};

ProjectItem.propTypes = {
  img: PropTypes.node,
  name: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.node,
};

export default ProjectItem;
