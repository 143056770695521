import styled from 'styled-components';

const ProjectsStyles = styled.div`
  padding: 10rem 0;
`;

function Projects() {
  return (
    <ProjectsStyles>
      <div className="container">
        <h2>Coming Soon</h2>
      </div>
    </ProjectsStyles>
  );
}

export default Projects;
