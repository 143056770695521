import PropTypes from 'prop-types';
import styled from 'styled-components';

const PTextStyles = styled.p`
  margin: 1rem 0;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: var(--gray);
  padding: 0.2rem 0 0.3rem;

  @media only screen and (min-width: 320px) {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
`;

function PText({ children }) {
  return <PTextStyles className="ptext">{children}</PTextStyles>;
}

PText.defaultProps = {
  children:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin egestas sagittis ultrices. Donec turpis nisl, placerat sed erat at, rutrum maximus tellus. Suspendisse id tortor elit.',
};

PText.propTypes = { children: PropTypes.string };

export default PText;
