import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ReactComponent as EdcaLogo } from '../assets/images/edca-logo.svg';

const FooterStyles = styled.footer`
  .footer__wrapper {
    border-top: 1px solid #e7e8e9;
    padding: 10rem 0;

    font-weight: 500;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    .col-1 {
      flex: 2;
    }

    .col-2,
    .col-3,
    .col-4 {
      flex: 1;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4 {
      padding: 1rem 0;
      margin: 2rem 0;

      @media only screen and (min-width: 768px) {
        padding: 0 1rem;
        margin: 0 1rem;
      }
    }

    h2 {
      margin-bottom: 2rem;

      br {
        display: none;

        @media only screen and (min-width: 768px) {
          display: inline;
        }
      }
    }

    p {
      color: var(--gray);
    }

    ul {
      margin-top: 1rem;

      li {
        padding: 0.5rem 0;
        color: var(--gray);

        a {
          color: var(--dark);

          position: relative;
          display: inline-flex;
        }

        a::after {
          content: '';
          position: absolute;
          display: inline-flex;
          bottom: -0.1rem;
          left: 0;
          width: 0;
          height: 0.1rem;
          background-color: var(--dark);
          transition: 0.3s;
        }

        a:hover::after {
          width: 100%;
        }
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    text-align: left;
    padding: 0 0 10rem 0;

    color: #cfd0d2;

    svg {
      height: 5rem;
      width: auto;

      filter: invert(96%) sepia(9%) saturate(35%) hue-rotate(182deg) brightness(88%) contrast(87%);
    }
  }
`;

function Footer() {
  const [t] = useTranslation();

  let dateCopy = new Date();
  dateCopy = dateCopy.getFullYear();

  return (
    <FooterStyles className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <div className="col-1">
            <h2>
              {t('footer.col-1.heading-1')} <br />
              {t('footer.col-1.heading-2')}&nbsp;—
            </h2>

            <p>{t('footer.col-1.description')}</p>

            <ul className="contact-links">
              <li>
                E: <a href="mailto:kchuquilinc17_1@unc.edu.pe">kchuquilinc17_1@unc.edu.pe</a>
              </li>
              <li>
                P: <a href="tel:+51 918 486 182">+51 918 486 182</a>
              </li>
            </ul>
          </div>

          <div className="col-2">
            <h2>
              {t('footer.col-2.heading-1')} <br />
              {t('footer.col-2.heading-2')}&nbsp;—
            </h2>

            <ul className="latest-projects">
              <li>
                <a href="/">Coming soon</a>
              </li>
              <li>
                <a href="/">Coming soon</a>
              </li>
              <li>
                <a href="/">Coming soon</a>
              </li>
              <li>
                <a href="/">Coming soon</a>
              </li>
            </ul>
          </div>

          <div className="col-3">
            <h2>
              {t('footer.col-3.heading-1')} <br />
              {t('footer.col-3.heading-2')}&nbsp;—
            </h2>

            <p>{t('footer.col-3.description')}</p>
          </div>

          <div className="col-4">
            <h2>
              {t('footer.col-4.heading-1')} <br />
              {t('footer.col-4.heading-2')}&nbsp;—
            </h2>

            <ul className="follow-me">
              <li>
                <a href="https://www.linkedin.com/in/edcadev" target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://twitter.com/edcadev" target="_blank" rel="noreferrer">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/edcadev" target="_blank" rel="noreferrer">
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/eduardo.cabanillas.dev"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="copyright">
          <EdcaLogo />
          <div>
            <p>&#169; 2021 - {dateCopy} Eduardo Cabanillas.</p>
            <p>{t('footer.copyright')}</p>
          </div>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
