import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import 'normalize.css';
import GlobalStyles from './styles/GlobalStyles';
import Typography from './styles/Typography';

import LoadingSpinner from './components/LoadingSpinner';
import App from './App';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'es'],
    fallbackLng: 'es',
    detection: {
      order: ['path', 'localStorage', 'htmlTag', 'cookie', 'subdomain'],
      caches: ['localStorage'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });

ReactDOM.render(
  <Suspense fallback={<LoadingSpinner />}>
    <React.StrictMode>
      <GlobalStyles />
      <Typography />
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
