import { v4 as uuidv4 } from 'uuid';
import ProjectImg from '../images/projects-img/projectImg.png';
import UTrackerImg from '../images/projects-img/utracker.jpg';
import GreenCtgImg from '../images/projects-img/greenctg.jpg';
import CoinTrackerImg from '../images/projects-img/cointracker.jpg';
import CavinImg from '../images/projects-img/cavinimg.jpg';

const projects = [
  {
    id: uuidv4(),
    name: 'U Tracker',
    desc: 'An application to track your all data from one place. I developed the website and the mobile app',
    img: UTrackerImg,
    link: 'http://google.com',
  },
  {
    id: uuidv4(),
    name: 'Green CTG',
    desc: 'An app to help people to get an overview of how they can make the city beautiful.',
    img: GreenCtgImg,
    link: 'http://google.com',
  },
  {
    id: uuidv4(),
    name: 'Coin Tracker',
    desc: 'Using this app you can track any e coin. Also you will get a good advise about investment form the professional',
    img: CoinTrackerImg,
    link: 'http://google.com',
  },
  {
    id: uuidv4(),
    name: "Cavin's Portfolio",
    desc: 'A portfolio for Cavin jr. A artist from New york city. The portfolio is made using ReactJs and GatsbyJs.',
    img: CavinImg,
    link: 'http://google.com',
  },
  {
    id: uuidv4(),
    name: 'Tracking Soft',
    desc: 'A tracking website that will show the performance of the website. Also you will get some useful advice to improve the performance.',
    img: ProjectImg,
    link: 'http://google.com',
  },
];

export default projects;
