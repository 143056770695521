import ContactBanner from '../components/ContactBanner';
import HeroSection from '../components/home/HeroSection';
// import InstagramSection from '../components/home/InstagramSection';
import ProjectsSection from '../components/home/ProjectsSection';

function Home() {
  return (
    <>
      <HeroSection />
      <ProjectsSection />
      {/* <InstagramSection /> */}
      <ContactBanner />
    </>
  );
}

export default Home;
