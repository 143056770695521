import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import TitleSection from '../TitleSection';
import PText from '../PText';
import LinkText from '../LinkText';
import HeroBg from '../../assets/images/home-bg.png';
import HomeBg2 from '../../assets/images/home-bg-designer.svg';
import ScrollButton from '../ScrollButton';

const HeroSectionStyles = styled.main`
  position: relative;
  width: 100%;
  padding-top: 40rem;

  @media only screen and (min-width: 568px) {
    padding-top: 10rem;
  }

  @media only screen and (min-width: 768px) {
    min-height: 100vh;
  }

  .home-bg2 {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: auto;
    top: calc(5rem + 5%);
    pointer-events: none;
    display: none;

    filter: drop-shadow(24px 24px 20px #f7f7f7);

    @media screen and (min-width: 940px) {
      display: block;
    }
  }

  .home-bg {
    background-image: url(${HeroBg});
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: top center;
    position: absolute;
    z-index: -9;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 45rem;
    top: 0;
    left: 0;
    margin-top: 7rem;

    @media only screen and (min-width: 568px) {
      background-position: top 0 right 0;
    }

    @media only screen and (min-width: 768px) {
      background-position: top 2rem right -12rem;
      max-height: none;
      margin-top: 0;
    }

    @media only screen and (min-width: 834px) {
      background-position: top 0 right -11rem;
    }

    @media only screen and (min-width: 1024px) {
      background-position: top -3rem right -5rem;
    }

    @media only screen and (min-width: 1194px) {
      background-position: top -3rem right 0;
    }

    @media only screen and (min-width: 1280px) {
      background-position: top -2rem right -2rem;
    }

    @media only screen and (min-width: 1366px) {
      background-position: top -2rem right 0;
    }

    @media only screen and (min-width: 1480px) {
      background-position: top 0 right 8rem;
    }

    @media only screen and (min-width: 1600px) {
      background-position: top 0 right 10rem;
    }

    @media only screen and (min-width: 1920px) {
      background-position: top 0 right 18rem;
    }

    @media only screen and (min-width: 2400px) {
      background-position: top 0 right 32rem;
    }
  }

  .wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 1.4rem;

    @media only screen and (min-width: 768px) {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }

    @media only screen and (min-width: 1280px) {
      padding: 0 4rem;
      bottom: 2rem;
    }
    @media only screen and (min-width: 1480px) {
      max-width: 140rem;
      margin: 0 auto;
    }

    .wrapper__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      .wrapper__description {
        width: 100%;
        @media only screen and (min-width: 568px) {
          width: 60%;
        }
        @media only screen and (min-width: 834px) {
          width: 60%;
        }
        @media only screen and (min-width: 1024px) {
          width: 60%;
        }
        @media only screen and (min-width: 1194px) {
          width: 50%;
        }
        @media only screen and (min-width: 1280px) {
          width: 60%;
        }
        @media only screen and (min-width: 1480px) {
          width: 45%;
        }
      }

      .links {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        span {
          margin: 0 0.5rem;
        }
      }
    }
  }

  .scroll-button {
    display: none;

    @media only screen and (min-width: 768px) {
      display: block;
    }
  }
`;

function HeroSection() {
  const [t] = useTranslation();

  return (
    <HeroSectionStyles className="home-top">
      <img src={HomeBg2} alt="designer." className="home-bg2" />
      <div className="home-bg" />
      <div className="wrapper">
        <div className="wrapper__row">
          <div className="wrapper__description">
            <TitleSection subheading="Eduardo Cabanillas" heading={t('home.home-top.title')} />
            <PText>{t('home.home-top.description')}</PText>
            <div className="links">
              <LinkText text={t('home.home-top.view-projects')} link="/projects" />{' '}
              <span>{t('home.home-top.or')}</span>
              <LinkText text={t('home.home-top.read-about-me')} link="/about" />
            </div>
          </div>
        </div>
        <ScrollButton />
      </div>
    </HeroSectionStyles>
  );
}

export default HeroSection;
