/* eslint-disable no-undef */

import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import styled from 'styled-components';

import NightRiderStyles from './NightRiderStyles';

const GoogleMapsStyles = styled.div`
  width: 100%;
  height: 20rem;

  @media only screen and (min-width: 520px) {
    height: 30rem;
  }

  @media only screen and (min-width: 768px) {
    width: 85%;
    height: 54rem;
  }

  .edca-location {
    color: var(--white);
  }
`;

function GoogleMaps() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBw3Br9cdowR51DYE7XBRwJvKFFrNIbEws',
  });

  const position = {
    lat: -7.1361274,
    lng: -78.5236118,
  };

  return (
    <GoogleMapsStyles className="google-map">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={position}
          zoom={15}
          options={{
            scrollwheel: false,
            mapTypeControl: false,
            zoomControlOptions: {
              position: google.maps.ControlPosition.TOP_LEFT,
              className: 'zoom-control-styles',
            },
            streetViewControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            styles: NightRiderStyles,
          }}
        >
          <Marker position={position} />
        </GoogleMap>
      ) : (
        <div />
      )}
    </GoogleMapsStyles>
  );
}

export default GoogleMaps;
