/* eslint-disable import/no-unresolved */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Autoplay, EffectFade, Pagination, Navigation } from 'swiper';

import TitleSection from '../components/TitleSection';
import PText from '../components/PText';
import ContactBanner from '../components/ContactBanner';

import AboutSlideImg from '../assets/data/aboutImg';

import PrevArrow from '../assets/images/prev-arrow.svg';
import NextArrow from '../assets/images/next-arrow.svg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Navigation]);

const AboutStyles = styled.div`
  padding: 10rem 0 0 0;

  @media only screen and (min-width: 768px) {
    padding: 15rem 0 0 0;
  }

  .title-section {
    @media only screen and (min-width: 768px) {
      text-align: center;
    }

    h1 {
      font-size: 2.2rem;

      @media only screen and (min-width: 768px) {
        font-size: 4.4rem;
      }
    }
  }

  .ptext {
    @media only screen and (min-width: 768px) {
      font-size: 2.2rem;
      text-align: center;
      margin: 3rem auto;
    }

    @media only screen and (min-width: 940px) {
      width: 80%;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin: 5rem 0;
    padding-bottom: 4.5rem;

    @media only screen and (min-width: 768px) {
      margin: 15rem 0;
      padding-bottom: 7.5rem;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 20rem;
    max-height: 60rem;

    @media only screen and (min-width: 768px) {
      height: 40rem;
    }

    @media only screen and (min-width: 940px) {
      height: 60rem;
    }
  }

  .swiper-pagination-bullet {
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    font-size: 12px;
    font-weight: bold;
    color: var(--white);
    opacity: 1;
    background: var(--dark);
  }

  .swiper-pagination-bullet-active {
    color: var(--white);
    background: var(--gray);
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    z-index: 20;
    background: rgba(15, 20, 30, 0.3);
    display: none;
    width: 45px;
    height: 45px;
    padding: 15px;
    border: 0 none;
    top: 50%;
    margin-top: -20px;
    border-radius: 50%;

    @media only screen and (min-width: 768px) {
      display: block;
      margin-top: -60px;
    }
  }

  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    background-color: var(--dark);
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .swiper-button-prev::after {
    background-image: url(${PrevArrow});
  }

  .swiper-button-next::after {
    background-image: url(${NextArrow});
  }

  .about-services-tools {
    margin: 10rem 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (min-width: 768px) {
      text-align: center;

      justify-content: space-between;
      align-items: center;
      margin: 10rem;

      .col-1,
      .col-2 {
        width: 45%;
      }
    }

    .col-2 {
      margin-top: 5rem;

      @media only screen and (min-width: 768px) {
        margin-top: 0;
      }
    }
  }

  .services-tools {
    h5 {
      font-size: 1.4rem;
      line-height: 1.25rem;
      text-transform: uppercase;
      color: var(--gray);
      letter-spacing: 0.4em;

      padding: 0.5rem 0;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 2.2rem;
      font-weight: 900;
      line-height: 3rem;

      em {
        color: #cfd0d2;
        margin: 0 1.5rem;
      }
    }
  }

  .contact-banner {
    border-top: 1px solid #e7e8e9;
  }
`;

function About() {
  const [t] = useTranslation();

  const pagination = {
    clickable: true,
    renderBullet(index, className) {
      return `<span class="${className}">0${index + 1}</span>`;
    },
  };

  return (
    <AboutStyles>
      <div className="container">
        <TitleSection subheading={t('about-me.subheading')} heading={t('about-me.heading')} />

        <PText>{t('about-me.description')}</PText>

        <Swiper
          spaceBetween={30}
          loop
          effect="fade"
          navigation
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={pagination}
          className="mySwiper"
        >
          {AboutSlideImg.map((aboutImg, index) => {
            if (index >= 9) return;
            return (
              <SwiperSlide key={aboutImg.id}>
                <img src={aboutImg.img} alt="" />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <section className="about-services-tools">
          <div className="services-tools col-1">
            <h5>{t('about-me.services-tools.services.heading')}</h5>
            <h3>
              {t('about-me.services-tools.services.design-services.web-mobile')}
              <em> / </em>
              {t('about-me.services-tools.services.design-services.ux-ui')}
              <em> / </em>
              {t('about-me.services-tools.services.design-services.iconography')}
              <em> / </em>
              {t('about-me.services-tools.services.design-services.illustration')}
              <em> / </em>
              {t('about-me.services-tools.services.design-services.photography')}
            </h3>
          </div>
          <div className="services-tools col-2">
            <h5>{t('about-me.services-tools.tools.heading')}</h5>
            <h3>
              Photoshop
              <em> / </em>
              Illustrator
              <em> / </em>
              Figma
              <em> / </em>
              Premiere Pro
              <em> / </em>
              Lightroom
            </h3>
          </div>
        </section>

        <ContactBanner />
      </div>
    </AboutStyles>
  );
}

export default About;
