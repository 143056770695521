import { v4 as uuidv4 } from 'uuid';

import Profile from '../images/about-img/profile.jpg';
import OneDayProgramming from '../images/about-img/one-day-programming.jpg';
import SetupDev from '../images/about-img/setup-dev.jpg';
import AwardHackatonGRC from '../images/about-img/award-hackaton-grc.jpg';
import EdcaGRC from '../images/about-img/edca-grc.jpg';

const aboutImg = [
  {
    id: uuidv4(),
    name: 'Perfil Eduardo Cabanillas',
    img: Profile,
  },
  {
    id: uuidv4(),
    name: 'One Day Programming',
    img: OneDayProgramming,
  },
  {
    id: uuidv4(),
    name: 'Setup Dev 2022',
    img: SetupDev,
  },
  {
    id: uuidv4(),
    name: 'Award Hackaton GRC',
    img: AwardHackatonGRC,
  },
  {
    id: uuidv4(),
    name: 'Eduardo Cabanillas in GRC',
    img: EdcaGRC,
  },
];

export default aboutImg;
