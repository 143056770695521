import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SmoothScrollbar from './components/SmoothScrollbar';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <SmoothScrollbar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
        <Footer />
      </SmoothScrollbar>
    </BrowserRouter>
  );
}

export default App;
