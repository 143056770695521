import DotLoader from 'react-spinners/DotLoader';
import styled from 'styled-components';

const LoadingSpinnerStyles = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LoadingSpinner() {
  return (
    <LoadingSpinnerStyles>
      <DotLoader size={100} />
    </LoadingSpinnerStyles>
  );
}

export default LoadingSpinner;
