/* eslint-disable import/no-unresolved */
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { EffectCoverflow } from 'swiper';

import TitleSection from '../TitleSection';

import Projects from '../../assets/data/projects';
import ProjectItem from '../ProjectItem';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import ArrowButton from '../ArrowButton';

// install Swiper modules
SwiperCore.use([EffectCoverflow]);

const PorjectsSectionStyles = styled.section`
  padding: 5rem 0;

  @media only screen and (min-width: 768px) {
    padding: 10rem 0;
  }

  .projects__items {
    margin-top: 5rem;
  }

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: contain;
    width: 35rem;
    height: auto;
    background-color: var(--dark);
    box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2);
    filter: blur(4px);
    border-radius: 12px;
  }

  .swiper-slide-active {
    filter: none;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    .swiper-slide {
      width: 40rem;
      height: auto;
    }

    .projects__items {
      margin-top: 0;
    }
  }
`;

function ProjectsSection() {
  const [t] = useTranslation();

  return (
    <PorjectsSectionStyles id="projects-section" className="projects-section">
      <div className="container">
        <TitleSection
          subheading={t('home.projects-section.subheading')}
          heading={t('home.projects-section.heading-1')}
        />
      </div>

      <div className="projects__items">
        <Swiper
          effect="coverflow"
          grabCursor
          centeredSlides
          slidesPerView="auto"
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          className="mySwiper"
        >
          {Projects.map((project, index) => {
            if (index >= 5) return;
            return (
              <SwiperSlide key={project.id}>
                <ProjectItem
                  img={project.img}
                  name={project.name}
                  description={project.desc}
                  link={project.link}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="container">
        <ArrowButton
          subheading={t('home.projects-section.subheading')}
          heading={t('home.projects-section.heading-2')}
          path="/projects"
        />
      </div>
    </PorjectsSectionStyles>
  );
}

export default ProjectsSection;
