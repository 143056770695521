import styled from 'styled-components';
import PropTypes from 'prop-types';

const TitleSectionStyles = styled.div`
  h5 {
    font-weight: 500;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: var(--gray);
    padding: 0.5rem 0;
    letter-spacing: 0.4em;
    line-height: 2rem;
  }

  h1 {
    font-weight: 800;
    font-size: 3.4rem;
    margin-top: 1rem;
    padding: 0.1rem 0;

    @media only screen and (min-width: 768px) {
      font-size: 6.2rem;
      margin-top: 1.5rem;
    }

    @media only screen and (min-width: 1280px) {
      font-size: 7.2rem;
    }
  }
`;

function TitleSection({ subheading, heading }) {
  return (
    <TitleSectionStyles className="title-section">
      <h5>{subheading}</h5>
      <h1>{heading}</h1>
    </TitleSectionStyles>
  );
}

TitleSection.defaultProps = {
  subheading: 'This is subheading',
  heading: 'This is heading',
};

TitleSection.propTypes = {
  subheading: PropTypes.string,
  heading: PropTypes.string,
};

export default TitleSection;
