import styled from 'styled-components';

const ScrollButtonStyles = styled.a`
  /* display: block; */
  /* z-index: 4; */
  height: 3rem;
  width: 2rem;
  margin: 2rem auto;

  .scroll-down {
    position: absolute;
    height: 3rem;
    width: 2rem;
    border: 2.3px solid var(--dark);
    border-radius: 25px;
    /* cursor: pointer; */
  }

  .scroll-down::before,
  .scroll-down::after {
    content: '';
    position: absolute;
    top: 20%;
    left: 50%;
    height: 0.5rem;
    width: 0.5rem;
    border: 2.3px solid var(--dark);
    border-top: transparent;
    border-left: transparent;
    transform: translate(-50%, -100%) rotate(45deg);
    animation: scroll-down 1s ease-in-out infinite;
  }

  .scroll-down::before {
    top: 30%;
    animation-delay: 0.3s;
  }

  @keyframes scroll-down {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      top: 90%;
      opacity: 0;
    }
  }
`;

function ScrollButton() {
  return (
    <ScrollButtonStyles href="#projects-section" className="scroll-button">
      <div className="scroll-down" />
    </ScrollButtonStyles>
  );
}

export default ScrollButton;
