import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowButton from './ArrowButton';

const ContactBannerStyles = styled.section`
  padding: 10rem 0;
`;

function ContactBanner() {
  const [t] = useTranslation();

  return (
    <ContactBannerStyles className="contact-banner">
      <div className="container">
        <ArrowButton
          subheading={t('contact-banner.subheading')}
          heading={t('contact-banner.heading')}
          path="/contact"
        />
      </div>
    </ContactBannerStyles>
  );
}

export default ContactBanner;
