// import { FaLanguage } from 'react-icons/fa';
import i18next from 'i18next';
import styled from 'styled-components';

const SwitcherLanguageStyles = styled.div`
  button {
    border: none;
    outline: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: var(--dark);
    color: var(--white);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0.1rem;
    cursor: pointer;

    &:hover {
      background-color: var(--gray);
    }
  }

  .langEN {
    margin-right: 0.9rem;
  }
`;

function SwitcherLanguage() {
  return (
    <SwitcherLanguageStyles className="switcher-language">
      <button
        type="button"
        onClick={() => {
          i18next.changeLanguage('es');
        }}
        className="langES"
      >
        ES
      </button>
      <button
        type="button"
        onClick={() => {
          i18next.changeLanguage('en');
        }}
        className="langEN"
      >
        EN
      </button>
    </SwitcherLanguageStyles>
  );
}

export default SwitcherLanguage;
