import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CgArrowRight } from 'react-icons/cg';
import styled from 'styled-components';

const ArrowButtonStyles = styled.div`
  padding: 0 0 5rem 0;

  @media only screen and (min-width: 768px) {
    text-align: center;
  }

  h5 {
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--gray-color-font);
    padding: 0.5rem 0;
    letter-spacing: 0.4em;
    line-height: 2rem;
  }

  h1 {
    font-size: 3.2rem;
    font-weight: 900;
    margin-top: 1rem;
    padding: 0.1rem 0;

    @media only screen and (min-width: 768px) {
      font-size: 6.2rem;
      margin-top: 1.5rem;
    }

    @media only screen and (min-width: 1280px) {
      font-size: 7.2rem;
    }

    a {
      position: relative;
      display: inline-flex;
      align-items: center;

      svg {
        height: 4rem;
        width: auto;
        margin-left: 1rem;

        @media only screen and (min-width: 768px) {
          height: 8rem;
        }
      }
    }

    a::after {
      content: '';
      position: absolute;
      display: inline-flex;
      bottom: -0.8rem;
      left: 0;
      width: 0;
      height: 0.5rem;
      background-color: var(--dark);
      transition: 0.3s;
    }

    a:hover::after {
      width: 100%;
    }
  }
`;

function ArrowButton({ subheading, heading, path }) {
  return (
    <ArrowButtonStyles className="arrow-button">
      <h5>{subheading}</h5>
      <h1>
        <Link to={path}>
          {heading} <CgArrowRight />
        </Link>
      </h1>
    </ArrowButtonStyles>
  );
}

ArrowButton.defaultProps = {
  subheading: 'This subheading',
  heading: 'This heading',
  path: '/',
};

ArrowButton.propTypes = {
  subheading: PropTypes.string,
  heading: PropTypes.string,
  path: PropTypes.node,
};
export default ArrowButton;
