import * as styled from 'styled-components';
import Inter from '../assets/fonts/Inter.woff';

export default styled.createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url(${Inter}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: optional;
  }

  body {
    font-family: 'Inter', Arial, sans-serif;
  }
`;
