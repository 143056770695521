import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkTextStyles = styled.div`
  a {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-transform: capitalize;
    color: var(--dark);

    position: relative;
    display: inline-flex;
  }

  a::after {
    content: '';
    position: absolute;
    display: inline-flex;
    bottom: -0.8rem;
    left: 0;
    width: 0;
    height: 0.2rem;
    background-color: var(--dark);
    transition: 0.3s;
  }

  a:hover::after {
    width: 100%;
  }

  @media only screen and (min-width: 480px) {
    a {
      font-size: 1.8rem;
    }
  }
`;

function LinkText({ text, link }) {
  return (
    <LinkTextStyles className="link-text">
      <h4>
        <Link to={link}>{text}</Link>
      </h4>
    </LinkTextStyles>
  );
}

LinkText.defaultProps = {
  text: 'This is link',
  link: '/',
};

LinkText.propTypes = {
  text: PropTypes.string,
  link: PropTypes.node,
};

export default LinkText;
