/* eslint-disable no-console */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
// import { useRef, useState } from 'react';
import { useRef } from 'react';
import { HiOutlineMail } from 'react-icons/hi';
import GoogleMaps from '../components/contact/GoogleMaps';
import TitleSection from '../components/TitleSection';
import PText from '../components/PText';

const ContactStyles = styled.div`
  padding: 10rem 0 0 0;
  @media only screen and (min-width: 768px) {
    padding: 15rem 0 0 0;
  }

  .contact-top {
    position: relative;
    width: 100%;
  }

  .google-map {
    position: absolute;
  }

  .wrapper-letters {
    position: relative;
    max-width: 148rem;
    width: 100%;
    height: 54rem;
    margin: 0 auto;
    padding: 0 4rem;

    display: none;

    @media only screen and (min-width: 768px) {
      display: block;
    }

    pointer-events: none;
  }

  .letters {
    width: 45rem;
    height: 45rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5rem;

    position: absolute;
    z-index: 1;
    top: -7rem;
    right: 25rem;

    & > i {
      font-size: 8rem;
      font-weight: 700;
      font-style: normal;
      color: #cfd0d2;
      text-align: center;
      padding: 2rem 3rem;
      position: relative;
    }

    & > i:nth-child(3):after {
      position: absolute;
      content: "'";
    }

    & > i:nth-child(5),
    & > i:nth-child(6),
    & > i:nth-child(7) {
      color: var(--white);
    }

    & > i:nth-child(8) {
      color: var(--dark);
      @media only screen and (min-width: 1700px) {
        color: var(--white);
      }
    }
  }

  .contact-form {
    padding: 25rem 0 10rem 0;
    @media only screen and (min-width: 520px) {
      padding: 35rem 0 10rem 0;
    }
    @media only screen and (min-width: 768px) {
      padding: 15rem 0 10rem 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }

    .contact-form__col-1 {
      flex: 2;
    }
    .contact-form__col-2 {
      flex: 3;
    }

    .contact-form__col-1 {
      .title-section {
        h1 {
          line-height: 5rem;
          @media only screen and (min-width: 768px) {
            font-size: 4rem;
          }
        }
      }
      .address {
        margin-top: 3rem;
        h5 {
          font-weight: 500;
          font-size: 1.4rem;
          text-transform: uppercase;
          color: var(--gray);
          padding: 0.5rem 0;
          letter-spacing: 0.4em;
          line-height: 2rem;
        }

        h4 {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2rem;
          margin-top: 0.5rem;
          padding: 0.1rem 0;

          @media only screen and (min-width: 768px) {
            line-height: 2.5rem;
          }
        }
      }
    }

    .contact-form__col-2 {
      @media only screen and (min-width: 768px) {
        padding-top: 5rem;
      }

      .ptext {
        @media only screen and (min-width: 768px) {
          line-height: 3rem;
          font-size: 2.2rem;
        }
      }

      .contact_form {
        label {
          display: block;
          cursor: text;
          font-weight: 400;
          font-size: 1.4rem;
          letter-spacing: 0.1rem;
          padding-top: 1px;
          padding-bottom: 4px;
          color: var(--gray);
        }

        input[type='email'],
        input[type='text'],
        textarea {
          background: var(--white);
          width: 100%;
          height: 4.5rem;
          min-height: 4.5rem;
          font-weight: 500;
          border: 1px solid #e7e8e9;
          border-width: 0 0 1px 0;

          resize: none;
          overflow-y: hidden;
          overflow-x: hidden;
        }

        input[type='email']::placeholder,
        input[type='text']::placeholder,
        textarea::placeholder {
          color: var(--dark);
        }

        input,
        select,
        textarea {
          outline: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border-radius: 0;
        }

        textarea {
          min-height: 4.5rem;
          resize: none;
          overflow-y: hidden;
          overflow-x: hidden;
        }

        select {
          background-color: transparent;
          border: none;
          width: 100%;
          height: 4.5rem;
          font-weight: 500;
          text-align: left;
          color: var(--dark);
          cursor: pointer;

          border: 1px solid #e7e8e9;
          border-width: 0 0 1px 0;
          border-radius: 0;

          padding: 1rem;
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;

          font-size: 2rem;
          font-weight: 700;

          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          svg {
            height: 2.5rem;
            width: auto;
            margin-right: 0.5rem;
          }

          &:hover {
            cursor: pointer;
          }

          &::after {
            content: '';
            position: absolute;
            display: inline-flex;
            bottom: -0.5rem;
            left: 0;
            width: 0;
            height: 0.1rem;
            background-color: var(--dark);
            transition: 0.3s;
          }

          &:hover::after {
            width: 100%;
          }
        }

        .form__fiel {
          position: relative;
        }

        .form__field:not(.error) input[type='email'] + .input-bottom-line,
        .form__field:not(.error) input[type='text'] + .input-bottom-line,
        .form__field:not(.error) textarea + .input-bottom-line {
          display: block;
          height: 1px;
          width: 0;
          position: absolute;
          background: var(--dark);
          bottom: 0;
          left: 0;
          transition: 0.25s;
        }
      }

      .contact_form {
        margin-top: 5rem;

        .form__field,
        .send-button {
          width: 100%;
          padding: 1rem;
          @media only screen and (min-width: 768px) {
            padding: 2rem;
          }
        }

        .row {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          @media only screen and (min-width: 768px) {
            flex-direction: row;
          }
        }
      }
    }
  }
`;
function Contact() {
  const [t] = useTranslation();

  // const [captchaValid, setCaptchaValid] = useState(null);
  // const [validMessage, setValidMessage] = useState(false);

  const captcha = useRef(null);

  const onChange = () => {
    if (captcha.current.getValue()) {
      // captchaValid(true);
    }
  };

  const submit = (e) => {
    e.preventDefault();
  };

  return (
    <ContactStyles>
      <section className="contact-top">
        <GoogleMaps />
        <div className="wrapper-letters">
          <div className="letters clearfix">
            <i>L</i>
            <i>E</i>
            <i>T</i>
            <i>S</i>
            <i>W</i>
            <i>O</i>
            <i>R</i>
            <i>K</i>
            <i>T</i>
            <i>O</i>
            <i>G</i>
            <i>E</i>
            <i>T</i>
            <i>H</i>
            <i>E</i>
            <i>R</i>
          </div>
        </div>
      </section>
      <div className="container">
        <section className="contact-form">
          <div className="contact-form__col-1">
            <TitleSection subheading={t('contact.subheading')} heading={t('contact.heading')} />
            <div className="address">
              <h5>{t('contact.address.title')}</h5>
              <h4>
                {t('contact.address.description.person')}
                <br />
                {t('contact.address.description.street')}
                <br />
                {t('contact.address.description.city')}
              </h4>
            </div>
          </div>
          <br />
          <div className="contact-form__col-2">
            <PText>{t('contact.form.heading')}</PText>
            <form className="contact_form" id="contact__form" onSubmit={submit}>
              <div className="row">
                <div className="form__field">
                  <label htmlFor="name">{t('contact.form.your-name.label')}</label>
                  <input
                    type="text"
                    id="contact-form__name"
                    name="name"
                    placeholder={t('contact.form.your-name.placeholder')}
                    required
                  />
                  <span className="input-bottom-line" />
                </div>
                <div className="form__field">
                  <label htmlFor="email">{t('contact.form.your-email.label')}</label>
                  <input
                    type="email"
                    id="contact-form__email"
                    name="email"
                    placeholder={t('contact.form.your-email.placeholder')}
                    required
                  />
                  <span className="input-bottom-line" />
                </div>
              </div>
              <div className="row">
                <div className="form__field">
                  <label htmlFor="service">{t('contact.form.service.label')}</label>
                  <select name="service" id="contact-form__service" required tabIndex={-1}>
                    <option value="" selected disabled>
                      {t('contact.form.service.placeholder')}
                    </option>
                    <option value={t('contact.form.service.option.option-1')}>
                      {t('contact.form.service.option.option-1')}
                    </option>
                    <option value={t('contact.form.service.option.option-2')}>
                      {t('contact.form.service.option.option-2')}
                    </option>
                    <option value={t('contact.form.service.option.option-3')}>
                      {t('contact.form.service.option.option-3')}
                    </option>
                    <option value={t('contact.form.service.option.option-4')}>
                      {t('contact.form.service.option.option-4')}
                    </option>
                  </select>
                </div>
                <div className="form__field">
                  <label htmlFor="budget">{t('contact.form.budget.label')}</label>
                  <select name="budget" id="contact-budget" required tabIndex={-1}>
                    <option value="" selected disabled>
                      {t('contact.form.budget.placeholder')}
                    </option>
                    <option value={t('contact.form.budget.option.option-1')}>
                      {t('contact.form.budget.option.option-1')}
                    </option>
                    <option value={t('contact.form.budget.option.option-2')}>
                      {t('contact.form.budget.option.option-2')}
                    </option>
                    <option value={t('contact.form.budget.option.option-3')}>
                      {t('contact.form.budget.option.option-3')}
                    </option>
                    <option value={t('contact.form.budget.option.option-4')}>
                      {t('contact.form.budget.option.option-4')}
                    </option>
                  </select>
                </div>
              </div>
              <div className="form__field">
                <label htmlFor="message">{t('contact.form.message.label')}</label>
                <textarea
                  name="message"
                  id="contact-form__message"
                  placeholder={t('contact.form.message.placeholder')}
                />
                <span className="input-bottom-line" />
              </div>
              <div className="form__field">
                <div className="contact__recaptcha">
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey="6Lcp_EweAAAAAGguAtFNZqwsZ_BpakAT4Mgzuzbb"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="send-button notification">
                <button type="submit">
                  <HiOutlineMail />
                  {t('contact.form.send-button')}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </ContactStyles>
  );
}

export default Contact;
