import styled from 'styled-components';

import { IoMenu, IoClose } from 'react-icons/io5';

import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { ReactComponent as EdcaLogo } from '../assets/images/edca-logo.svg';
import { ReactComponent as EdcaLogoLetters } from '../assets/images/edca-letters.svg';
import SwitcherLanguage from './SwitcherLanguage';

const HeaderStyles = styled.header`
  position: relative;

  .navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${({ showMenu }) => (!showMenu ? 'rgba(253, 255, 252, 0.8)' : 'transparent')};

    @media only screen and (min-width: 768px) {
      background-color: transparent;
    }

    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 1rem;

    @media only screen and (min-width: 768px) {
      padding: 2rem;
    }

    .navBar__col-left {
      .logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .edca-logo svg {
          height: 3rem;
          width: auto;
          margin-right: 0.5rem;

          @media only screen and (min-width: 375px) {
            height: 3.5rem;
            margin-right: 1rem;
          }

          @media only screen and (min-width: 768px) {
            height: 4rem;
          }
        }

        .edca-letters svg {
          height: 2rem;
          width: auto;

          @media only screen and (min-width: 375px) {
            height: 2.5rem;
          }

          @media only screen and (min-width: 768px) {
            height: 3rem;
          }
        }

        &:hover {
          filter: brightness(0) saturate(100%) invert(59%) sepia(8%) saturate(195%)
            hue-rotate(179deg) brightness(91%) contrast(93%);
        }
      }
    }

    .navBar__col-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .navMenuIcon,
      .closeMenuIcon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.4rem;
          letter-spacing: 0.2rem;
          margin-right: 0.5rem;
        }

        svg {
          height: 3rem;
          width: auto;
          display: none;

          @media only screen and (min-width: 375px) {
            display: block;
          }
        }

        &:hover {
          cursor: pointer;
          color: var(--gray);
        }
      }
    }
  }

  .hide-navBar,
  .hide-navMenu {
    display: none;
  }

  .navMenu {
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: var(--dark);
    color: var(--gray);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .navBar {
      /* position: initial; */

      .navBar__col-left {
        .logo {
          filter: brightness(0) saturate(100%) invert(59%) sepia(8%) saturate(195%)
            hue-rotate(179deg) brightness(91%) contrast(93%);

          &:hover {
            filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(1777%)
              hue-rotate(18deg) brightness(89%) contrast(134%);
          }
        }
      }

      .navBar__col-right {
        .switcher-language {
          button {
            background-color: var(--gray);

            &:hover {
              background-color: var(--white);
              color: var(--dark);
            }
          }
        }

        .closeMenuIcon {
          &:hover {
            color: var(--white);
          }
        }
      }
    }

    .copyright {
      margin: 1rem 0;
      text-align: center;
      font-size: 1.4rem;

      position: fixed;
      top: 95%;
      /* transition: position 0.8s; */

      span {
        display: none;

        @media only screen and (min-width: 768px) {
          display: inline-block;
        }
      }
    }
  }

  .navMenu__items {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */

    p {
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    ul {
      text-align: center;
      width: 100%;

      li {
        display: block;
        padding: 1rem 0;

        a {
          font-size: 4rem;
          font-weight: 900;

          @media only screen and (min-width: 768px) {
            font-size: 6.2rem;
          }

          &:hover {
            color: var(--white);
          }
        }
      }

      .active {
        color: var(--white);
      }

      @media only screen and (min-width: 768px) {
        /* For button bar of .menu__link */
        .active::after {
          width: 10rem;
        }
      }
    }

    .menu__link {
      position: relative;
      display: inline-flex;
    }

    .menu__link::after {
      content: '';
      position: absolute;
      display: inline-flex;
      top: 50%;
      left: -12rem;
      width: 0;
      height: 0.8rem;
      background-color: var(--white);
      transition: 0.3s;
    }

    .menu__link:hover::after {
      width: 10rem;
    }
  }
`;

function Header() {
  const [t] = useTranslation();

  const [showMenu, setShowMenu] = useState(false);

  let dateCopy = new Date();
  dateCopy = dateCopy.getFullYear();

  if (!showMenu) {
    enableBodyScroll(document);
  } else {
    disableBodyScroll(document);
  }

  return (
    <HeaderStyles className="header" showMenu={showMenu}>
      <div className={!showMenu ? 'show-navBar' : 'hide-navBar'}>
        <div className="navBar">
          <div className="navBar__col-left">
            <div className="logo">
              <a href="/" className="edca-logo">
                <EdcaLogo />
              </a>

              <a href="/" className="edca-letters">
                <EdcaLogoLetters />
              </a>
            </div>
          </div>

          <div className="navBar__col-right">
            <SwitcherLanguage />

            <div
              className="navMenuIcon"
              onClick={() => setShowMenu(!showMenu)}
              role="button"
              onKeyDown={() => setShowMenu(!showMenu)}
              tabIndex={0}
            >
              <p>{t('header.menu')}</p>
              <IoMenu />
            </div>
          </div>
        </div>
      </div>

      <div className={!showMenu ? 'hide-navMenu' : 'show-navMenu'}>
        <div className="navMenu">
          <div className="navBar">
            <div className="navBar__col-left">
              <div className="logo">
                <a href="/" className="edca-logo">
                  <EdcaLogo />
                </a>

                <a href="/" className="edca-letters">
                  <EdcaLogoLetters />
                </a>
              </div>
            </div>

            <div className="navBar__col-right">
              <SwitcherLanguage />

              <div
                className="closeMenuIcon"
                onClick={() => setShowMenu(!showMenu)}
                role="button"
                onKeyDown={() => setShowMenu(!showMenu)}
                tabIndex={0}
              >
                <p>{t('header.close-menu')}</p>
                <IoClose />
              </div>
            </div>
          </div>

          <div className="navMenu__items">
            <p>Menu</p>
            <ul>
              <li>
                <NavLink
                  className="menu__link"
                  as={Link}
                  to="/"
                  exact
                  onClick={() => setShowMenu(!showMenu)}
                  role="button"
                  onKeyDown={() => setShowMenu(!showMenu)}
                  tabIndex={0}
                >
                  {t('header.nav-item.home')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu__link"
                  as={Link}
                  to="/about"
                  exact
                  onClick={() => setShowMenu(!showMenu)}
                  role="button"
                  onKeyDown={() => setShowMenu(!showMenu)}
                  tabIndex={0}
                >
                  {t('header.nav-item.about-me')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu__link"
                  as={Link}
                  to="/contact"
                  exact
                  onClick={() => setShowMenu(!showMenu)}
                  role="button"
                  onKeyDown={() => setShowMenu(!showMenu)}
                  tabIndex={0}
                >
                  {t('header.nav-item.contact')}
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="menu__link"
                  as={Link}
                  to="/projects"
                  exact
                  onClick={() => setShowMenu(!showMenu)}
                  role="button"
                  onKeyDown={() => setShowMenu(!showMenu)}
                  tabIndex={0}
                >
                  {t('header.nav-item.projects')}
                </NavLink>
              </li>
              <li>
                <a
                  className="menu__link"
                  href="https://www.instagram.com/edcadev"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setShowMenu(!showMenu)}
                  role="button"
                  onKeyDown={() => setShowMenu(!showMenu)}
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className="menu__link"
                  href="https://www.linkedin.com/in/edcadev"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setShowMenu(!showMenu)}
                  role="button"
                  onKeyDown={() => setShowMenu(!showMenu)}
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>

          <p className="copyright">
            &#169; {dateCopy} Eduardo Cabanillas. <span>{t('header.footer.copyright')}</span>
          </p>
        </div>
      </div>
    </HeaderStyles>
  );
}

export default Header;
